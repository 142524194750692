/* *{
    overflow-x: hidden; */

.day_update_line{
    display: flex;
    flex-direction: row;
    /* justify-content: center; */
    align-items: center;
    margin-top: 10px;
    margin-left: 32px;
    margin-right: 24px;
}
.day_update_line_left{
    background: #1954E7;
    border-radius: 2px;
    height: 22px;
    padding-top: 3px;
   width: 160px;
    display: flex;
    justify-content: center;
   
   

}
.days_label{
    display: flex;
    flex-direction: row;
    /* padding: 3px 0px 1px 0px; */
    /* border-radius: 5px Solid green; */
    /* border-style: solid; */
}
.days_label_days{
    border-style:inset;
    border: 0;
    /* padding: 3px 0px 1px 0px; */
    
}
.days_label_days_p{
    padding-right: 15px;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 18px;
    color: #FFFFFF;
    border-style:inset;
    border: 0;
    border-right: 1px solid #FFFFFF;
   
}
.days_label_dates{
    margin-left: 7px;
}
.days_label_dates p{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF;
}

.day_update_line_right{
    width: calc( 100% - 164px );
    margin-left: 4px;
    height: 1px;
    background: linear-gradient(180deg, #9E9999 0%, rgba(232, 232, 232, 0) 100%);
    /* border: 1px solid black; */
}
hr {
    margin: auto 0px;
    border: 1px solid rgb(150, 150, 150);
  }

.upcoming_classes_div{
    margin-left: 16px;
    margin-right: 8px;
    width: 100%;
   

}