
.class_status_container
{
  /* border:2px solid red; */
  display: flex;
  align-items: center;
  height:63vh;

}

.class_status_inner_container{
    height: fit-content;
    width: fit-content;
    align-items: center;
    text-align: center;
    width: 100%;
    background: #E5E5E5;
    /* border: 1px solid green; */
   
}

.class_status_inner_container p{
    color:rgba(51, 77, 110, 1) ;
    font-style: normal;
    font-size: 24px;
    fill: Solid;
    letter-spacing: 1%;
    line-height: 36px;
    margin-bottom: 2vh;
    /* padding-top: 134px; */
    
}
.class_status_inner_container img{
    /* margin-bottom: 135px; */
    /* border: 2px solid black; */

}