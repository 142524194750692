.pop-up-box-exam{
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    z-index: 1;
    left: 0;
}

.box-exam{
    position: relative;
    
    width: fit-content;
    margin: 0 auto;
    height: fit-content;
      top:20%;
    /* margin-top: calc(100vh - 85vh - 20px); */
    background: #fff;
    border-radius: 4px;
    padding: 0px 20px 2px 20px;
    border: 1px solid #999;
}

.can-button{
    border:0;
    outline: 0;
    padding: 10px;
    background: none;
    display: flex;
    float: right;
    cursor: pointer;;
}
.view-exam-div{
    /* border:1px solid red; */
    margin-top: 30px;
    margin-left: 20px;
}