.filter-container{
    display:flex;
    align-items:flex-start;
    width:100%;
    justify-content: space-between;
    /* border-top: 2px solid #dddfe3; */
    border-bottom: 1px solid #dddfe3;
    /* border:1px solid black; */
    height: fit-content;
    padding: 5px;
  
}
.reset{
    display:flex;
    padding: 10px;
    width:100%;
    /* border: 1px solid black; */
    overflow-x: auto;
}



.Study-filter-neet1{
    /* margin-top: 2px;
    background: #1953E7;
    margin-right: 2%;
  
    border: 1px solid #DFDFDF;
    box-sizing: border-box;
   
    cursor: pointer; */
    font-family: Lato;
font-size: 13px;
font-style: normal;
font-weight: 400;
/* line-height: 20px; */
letter-spacing: 0.01em;
/* text-align: left; */
padding: 8px;
background-color: #1953E7;
/* border-radius: 5px; */
border: 1px solid #DFDFDF;
border-radius: 7px;
cursor: pointer;
margin-left: 10px;
width: fit-content;
white-space:nowrap;
color: #FFFFFF;
   
  
}
.Study-filter-text1{
 font-family: Lato;
font-size: 13px;
font-style: normal;
font-weight: 400;
/* line-height: 20px; */
letter-spacing: 0.01em;
/* text-align: left; */
padding: 8px;
border: 1px solid #DFDFDF;
border-radius: 7px;
cursor: pointer;
margin-left: 10px;
width: fit-content;
white-space:nowrap;
color: #6D6060;
}

.Study-filter-heading-text-neet{
font-family: Lato;
font-style: normal;
font-weight: 500;
font-size: 13px;
line-height: 19px;
letter-spacing: 0.01em;
text-transform: capitalize;
color: #FFFFFF;
padding: 8px;
}

.card-container{
border:1px solid blue;
background-color: #E5E5E5;

}
.search-study{
    border:1px solid #D7D5EA;
    border-radius: 5px;
    padding: 5px;
    margin-right: 20px;
}

@media screen and (max-width: 500px ) {
    .filter-container{
    display: flex;
    flex-direction:  column-reverse;   
    }
    .reset{
    width: 92%;
    }
    .searchhhhhhhhhhh{
        /* margin-left: 10px; */
        width: 100%;
    }
    


}
