.Announcementpopup-box {
    position: fixed;
    background: #00000050;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 2;
  }
  
.Announcement_box{
    display: flex;
    flex-direction: column;
    /* align-items: center;
    text-align: center; */
    width:60%;
    border-radius: 7px;
    background-color: #fafafa;
}
.Announcement_msg{
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100vh;
}
.Announcement_Header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 34px;
    padding: 7px;
}
.Announcement_title{
margin-left: 15px;
}
.Announcement_title p{
    font-size: 16px;
}

.Announcement_button{
margin-right: 15px;
}
.Announcement_body{
    margin:15px;
}