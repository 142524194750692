
  
 
  h1,
  h2 {
    color: #333333;
  }
  
  .btn--alt {
    background-color: transparent;
    color: #800040;
  }
  
  .btn--alt:hover {
    background-color: #f8dae9;
  }
  
  .card {
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    width: 20rem;
    height: 10 rem;
  }
  
  .actions {
    text-align: right;
  }
  
  .modal {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    background-color: white;
    padding: 1rem;
    text-align: center;
    width: 30rem;
    z-index: 10;
    position: fixed;
    top: 20vh;
    left: calc(50% - 15rem);
  }
  
  .backdrop {
    position: fixed;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.75);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
  