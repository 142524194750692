.audioplayer{
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    /* top: 0; */
    left: 0;
    justify-content: center;
    align-items: center;
    display: flex;
   
}

.audio{
    width:700px ;
    height:auto;
    background-color: black;
    /* border: 1px solid black; */
    /* margin-top: 15%;
    margin-left: 37%; */
    border-radius: 5px;
   
}

.audio-popup{
     margin-top: 40px;
     margin-bottom: 10px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: auto;
    height:auto;
    /* padding: 0px 40px 0px 40px; */
    /* border:1px solid blue; */

}

.audio-popup iframe{
    width: 100%;
    height:400px;
  }

  .audio-popup img{
    width: 100%;
    height:400px;
  }

.audio-button{
    display: flex;
    float: right;
    margin:10px;
    cursor: pointer;
    border:0;
    outline:0;
    
  
}

.video-player {
    /* border:1px solid black; */
    width: 100%;
  height:320px;
}
