.popup-box {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
  }
  .video-box{
    position: relative;
    
    width: 70%;
    margin: 0 auto;
    height: 80%;
   top:10%;
    /* margin-top: calc(100vh - 85vh - 20px); */
    background: #fff;
    border-radius: 4px;
    padding: 0px 20px 2px 20px;
    border: 1px solid #999;
   

  }
   
  .box {
    position: relative;
    width: 70%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: 10vh;
    /* margin-top: calc(100vh - 85vh - 20px); */
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
  }
  .video-top{
      display: flex;
      justify-content: space-between;
      margin: 2px;
  }
.view_record_main{
    background-color:white;
    
}
.view_recording{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
}
.view_recording_left{
    border-bottom: 1px solid blue;
}
.view_recording_left p {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #334D6E;
}
.view_recording_left button{
    outline: 0;
    border: 0;
    color: #334D6E;
    font-weight: 600;
    padding-bottom: 8px;
    background-color: #ffffff;
    cursor: pointer;

}
.view_recording_right button{
    outline: 0;
    border: 0;
    background-color: #FFFFFF;
    cursor: pointer;

}