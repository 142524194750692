
    .vimeo-popup-box {
      position: fixed;
      background: #00000050;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      justify-content: center;
      align-items: center;
      display: flex;
      }
       
      .vimeo-box {
        position: relative;
        width: 50%;
        margin: 0 auto;
        height: auto;
        max-height: 70vh;
        /* margin-top: 20%; */
        /* margin-top: calc(100vh - 85vh - 20px); */
        background: #fff;
        border-radius: 4px;
        padding: 20px;
        border: 1px solid #999;
        display: flex;
        flex-direction: column;
        /* overflow: auto; */
      }
    /************************new****************************/
    .vimeo-box-cointainer{
        display: flex;
        flex-direction:row;
        justify-content: space-between;
    }
    .cancel_button{
      border:0;
      outline:0;
      background-color: white;
      cursor: pointer;
    }
    .vimeo-box-cointainer-right img{
        height: 14px;
        width:  14px;
    }
    .vimeo-box-second-line{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* border:1px solid black; */
        padding: 10px;
    }

