.header_container{
    display: flex;
    flex-direction: column;
    
}
.header_top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 7px;
    
}
.header_top_left{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 26px;
}
.header_top_left_img{
    margin-right: 7px;
    /* min-height: 0px; */

}
.header_top_left_text{
    font-family: Lato;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #334D6E;
    
}

.header_top_right{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 26px;

}
.notification_div{
    margin-right: 26px;

}
.Announcement_Icon{
    margin-right: 26px;
}
.Announcement_Icon button{
    outline: 0;
    border: 0;
    background: 0;
    cursor: pointer;
}

.profile_div button{
    outline: 0;
    border: 0;
    background: 0;
    cursor: pointer;

}

.header_menubar{
    background: linear-gradient(90deg, #1953E7 7.67%, rgba(4, 222, 222, 0.79) 109.54%);
    width: 100%;
    padding-top: 10px;
    /* padding-bottom: 10px; */
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    overflow-x: auto;

}
.header_menu{
    display: flex;
    flex-direction: row;
    margin-left: 35px;
}
.menu_button{
    align-items: center;
    padding: 5px;
    text-align: center;
   width: 100px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
}
.menu_button_on_click{
    align-items: center;
    padding: 5px;
    text-align: center;
    width: 100px;
    font-family: Helvetica;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    border-bottom: 2px solid #FFFFFF;
    background: linear-gradient(360deg, rgba(255, 255, 255, 0.3) 16.43%, rgba(255, 255, 255, 0) 111.43%);
}
a{
    color: #FFFFFF;
    text-decoration: none;

}
.header_social_icon{
    display: flex;
    flex-direction: row;
    margin-right: 26px;
    cursor: pointer;

}
.header_social_icon a{
    margin-left: 5px;
}
@media screen and (max-width: 480px) {
    .menu_button{
        width: 90px; 
    }
    .menu_button_on_click{
        width: 90px;
    }
    .header_menu{
        margin-left: 0px;
    }
  }