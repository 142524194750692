.pastview_pdf_main_div{
    border: 1px solid #EBEBEB;
    box-sizing: border-box;
    border-radius: 2px;
    margin-top: 10px;

}
hr{
    margin: auto 0px;
    border: 1px solid #22D08D;
}
.pastview_pdf_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 12px;

}
.pastview_pdf_container .pastview_Downlode_button {
    display: flex;
}
.pastview_pdf_container:hover  .pastview_Downlode_button {
    display: flex;
    

}

.pastview_pdf_img{
    display: flex;

}
.pastview_pdf_details{
    margin-left: 12px;
    flex: 2;

}
.pastview_pdf_name{
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0.01em;
    color: #454545;

}
.pastview_pdf_size{
    margin-top: 5px;
    display: flex;
    flex-direction: row;

}
.size_title{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #8B8B8B;
}
.size_value{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 10px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    margin-left: 5px;
    color: #454545;
    
}



.pastview_Downlode_button button{
    background: #0FAA0C;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 10px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #FFFFFF;
    height: 30px;
    width: 94px;
    border-radius: 5px;
    outline: 0;
    border: 0;
    cursor: pointer; 
}
