
.comparision-container{
  /* border:1px solid red; */
  background: #E5E5E5;
  height: fit-content;
  width: 100%;
  overflow-x: auto;
}

.comparision-div{
  /* border:1px solid blue; */
  height: fit-content;
  background: #FFFFFF;
  margin-top: 5px;
 
  min-width: 700px;

}

.comparision-div2{
  /* border:1px solid magenta; */
  display: flex;
  justify-content: space-between;
  
  margin-left: 10px;
  margin-right:10px ;
 padding: 10px;
 
}
.comp-head{
font-family: Lato;
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 18px;
letter-spacing: 0.01em;
text-transform: capitalize;
color: #454545;
}
.comp-heading{
  display: flex;
}
.comparision-dot{
  height:10px;
  width: 10px;
  /* border:1px solid black; */
  border-radius: 20px;
  margin-top: 3px;
  margin-right: 7px;
  background-color: blue;
}

#comp {
  border-collapse: collapse;
  width: 100%;
}


/* #comp tr:nth-child(odd){background-color: #E0F8FF;} */

 #comp th {
  border-right: 1px solid #ddd;
  padding: 20px;
}  

#comp td{
  padding: 20px;

}

#comp th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background: #EDEDED;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */
  
  letter-spacing: 0.01em;
  text-transform: capitalize;
  
  color: #454545;

}

#comp td {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height, or 138% */

  letter-spacing: 0.01em;
  text-transform: capitalize;

  color: #454545;
}

.comp-div{
     /* border:1px solid red; */
     margin-left: 20px;
     margin-right:20px ;
}

.comp-img{
    /* margin-top: 5px; */
    /* border: 1px solid green; */
    /* margin-bottom: 5px; */
    margin-right: 5px;
}


.comp-maindiv1{
    display: flex;
    /* border:1px solid red; */
    justify-content: center;
}
 
 