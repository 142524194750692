/* ==================================================================FOR MONTH VIEW========================================================*/
.tt_table_calendar2 tr {
  border: 1px solid #edeaea;
  /* height:auto; */
}

.tt_table_calendar2 td {
  height: 60px;
  border: 1px solid #edeaea;
  cursor: pointer;
}

.tt_table_calendar2 th {
}
.tt_table_calendar2 td:hover {
  background-color: rgb(220, 220, 220);
  transition: background-color 0.4s;
}

.fixed_days_tr {
  background: #f8faff;
  /* height:100px; */
  /* border:2px solid red; */
}
.tt2_table_container table {
  height: 50vh;
  /* border:1px solid #EDEAEA; */
}

/* .tt2_table_container  td
{
    border:2px solid green;
} */

.if_schedule_change_background_tt2_table {
  background: #cddbff;
}

.table_tt_month_view {
  display: flex;
  flex-direction: column;

  border: 1px solid #edeaea;
}

.table_tt_month_view tr {
  /* height:35px; */
  /* border:2px solid red; */
}
.skip_d_months_container_tt2 {
  width: 100%;
  height: 35px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #edeaea;
}

.skip_d_month_inner_container {
  width: 25%;
  font-size: 10px;
  font-weight: bold;
  color: #1954e7;
  /* border:2px solid blue; */
}
.skip_d_month_inner_container2 {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}
.previous_n_next_month_tt2 {
  color: black;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.previous_n_next_month_tt2 div {
  font-size: 18px;
}

.calendar_image_n_text_div_tt2 {
  display: flex;
  justify-content: space-evenly;
}
.calendar_image_div_tt2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border:1px solid red; */
}

.calendar_text_div_tt2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* border:1px solid red; */
}
.tt2_image {
  /* border:1px solid black; */
  height: fit-content;
}
.tt2_image img {
  margin-top: 6px;
}
.tt2_calender_text {
  font-weight: bold;
  /* border:2px solid blue; */
  font-size: 13px;
}

.table_month_tt_inner_container {
  width: 100%;
  /* width:fit-content; */
  background-color: #fff;

  /* border:2px solid red; */
}

.table_tt_month_view table {
  width: 100%;
  height: 55vh;
}

.skip_d_months_tt2 {
}

.fixed_days_container {
  width: 65px;
  /* height:30px; */
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* border:2px solid red; */
}

.fixed_days {
  /* height:20px; */
  text-align: center;
  font-size: 8px;
  font-weight: bold;
  font-size: 10px;

  /* border:2px solid blue; */
}

.days_n_schedule_tt2_container {
  /* width:65px; */
  height: 60px;
  display: flex;
  flex-direction: column;

  /* text-align:end; */

  /* border:1px solid red; */
}
.dates_tt2_table {
  /* top:0; */
  margin-top: 5px;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  /* border:1px solid yellow; */
}

.days_tt2 {
  width: 15px;
  font-weight: bold;
  font-size: 10px;
  /* border:2px solid green; */
}

.schedule_tt2_table_container {
  height: 80%;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  flex-direction: column;

  width: 100%;
  /* border:2px solid blue; */
}

.schedule_digits_tt2 {
  text-align: center;
  color: #1954e7;
  font-weight: bold;
  font-size: 17px;
  /* border:1px solid violet; */
}

.schedule_text_tt2 {
  text-align: center;
  font-weight: bold;
  font-size: 8px;
  /* border:1px solid brown; */
}

/*===================================================================================FOR YEAR VIEW===================================================*/

.table_year_tt_inner_container {
  display: flex;
  font-family: Lato;
  font-size: 20px;
  background: white;
  /* width:600px; */
  /* border:2px solid brown; */
}
.table_year_tt_inner_container table {
  /* border:1px solid #EDEAEA; */
  width: 100%;
  border-collapse: collapse;
}

.table_year_tt_inner_container table td:hover {
  background-color: rgb(220, 220, 220);
  transition: background-color 0.4s;
}
.table_year_tt_inner_container tr {
  /* height:100px; */
  height: 118px;

  width: 600px;
  border: 1px solid #edeaea;
  /* border:2px solid red; */
}

.table_year_tt_inner_container {
  border: 1px solid #edeaea;
}

.table_year_tt_inner_container td {
  width: 175px;
  /* height:102px; */
  padding: 5px 5px 5px 5px;
  cursor: pointer;

  border: 1px solid #edeaea;
}

.month_container_year_tt {
  height: 100px;
  /* border:2px solid red; */
}

.month_name_parent_year_tt {
  height: 30%;
  display: flex;
  justify-content: flex-end;
  /* border:2px solid red; */
}

.month_name_year_tt {
  text-align: right;
  width: 40%;
  font-size: 12px;
  /* border:2px solid green; */
}

.schedules_year_tt {
  height: 70%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* border:2px solid red; */
}
.schedules_year_tt div {
  height: fit-content;
  /* border:2px solid green; */
}
.schedule_count_tt_year {
  font-weight: bold;
  color: #1954e7;
}

.schedules_text_tt_year {
  height: fit-content;
  font-size: 10px;
  /* border:1px solid black; */
}

.month_container_year_tt {
  height: 18.7vh;
}

/*===================================================================================================================================*/
@media screen and (min-width: 1400px) {
  .month_container_year_tt {
    height: 18.7vh;
  }

  .tt_table_calendar2 td {
    height: 11vh;
  }
}
@media screen and (max-width:1250px) and (min-width:1136px)
{
  .month_container_year_tt
  {
    height:17vh;
    /* border:2px solid red; */
  }
}

@media screen and (max-width: 1135px) {
  .table_month_tt_inner_container {
    width: fit-content;
  }
  .skip_d_month_inner_container
  {
    width: 35%;
  }
}

@media screen and (max-width: 800px) {
  .table_tt_month_view {
    width: 100%;
  }

  .table_month_tt_inner_container {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .table_year_tt_inner_container td {
    width: 150px;
  }

  .table_year_tt_inner_container {
    width: 600px;
    /* border:2px solid red; */
  }
  .table_year_tt_inner_container table {
    width: auto;
    /* border:2px solid red; */
  }
}

@media screen and (max-width: 465px) {
  .table_month_tt_inner_container {
    width: fit-content;
  }
}

@media screen and (max-width: 400px) {
  .skip_d_months_container_tt2 {
    justify-content: start;
  }
  .skip_d_month_inner_container {
    margin-left: 100px;
  }
}

@media screen and (max-width: 280px) {
  .skip_d_month_inner_container {
    margin-left: 60px;
  }
  .skip_d_month_inner_container {
    width: 150px;
    /* border:2px solid red; */
  }
  .skip_d_month_inner_container2 {
    width: 150px;
  }
}

@media screen and (max-width: 170px) {
  .skip_d_month_inner_container {
    margin-left: 5px;
  }
}
