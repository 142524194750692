.subject-container{
    /* border:1px solid blueviolet; */
    background-color:#FaFaFa ;
    display: flex;
    
    
    }
    
    .subject-card{
     /* border:1px solid purple; */
     width:20%;
     padding: 5px;
      height : fit-content;
      margin-top: 1%;
      margin-left: 2%;
      background-color: #ffffff;
    }
    .subject-name{
        border-bottom: 0.5px solid #ccc;
        /* height:fit-content; */
       align-items: center;
       display: flex;
       /* flex-direction: row; */
        justify-content: space-between;
       /* border:1px solid red; */
       cursor: pointer;
      
       
    }
    .dv1{
        /* border: 1px solid royalblue; */
        display: flex;
        /* height: 50px; */
        margin-left: 7px;
        /* width: fit-content; */
    }

    .dv2{
        /* border: 1px solid gold; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-right: 9px;
       
    }

    .subject-name-image{
        /* margin-left: 12px; */
        /* border:1px solid orange; */
        width: 30px;
        border-radius: 20px;
        height: 30px;
        text-align: center;
        padding: 1px;
        margin: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        

        font-family: Lato;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 13px;


        letter-spacing: 0.01em;
        text-transform: capitalize;

        color: #FFFFFF; 
    }


    .subject-name-image p{
        
    }


    .subject-name-heading{
       /* margin-bottom:12px ; */
       font-family: Lato;
       font-style: normal;
       font-weight: normal;
       font-size: 13px;
       letter-spacing: 0.01em;
       color: #6D6060;
       /* text-align: center;
       justify-content: center; */
       /* border: 1px solid green; */
       margin-left: 9px;
       margin-top: 12px;
       width: 140px;
       white-space:nowrap;
       overflow:hidden;
       text-overflow:ellipsis;
    }
    
    .new-files{
        
        margin-right:8px; 
        background: #FE8B37;
        border-radius: 10px;
        font-family: Lato;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
        letter-spacing: 0.01em;
        color: #FFFFFF;
        padding: 2px;
        text-align: center;
        width: 60px;
        display: inline;
        /* border:1px solid chartreuse; */
        height: fit-content;
        margin-top: 5px;
        cursor: pointer;

    }

    
    .arrow {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        /* margin-left: 5px; */
        margin-right: 6px;
        cursor: pointer;
       
      }
      
      .right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }

      .down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        margin-bottom: 3px;

      }
        .every-button{
            border:0;
            outline: 0;
        background: none;
        }

        .container{
            /* border: 1px solid black; */
            width: 80%;
            margin-left:8px;
            height: fit-content;
        }
            .icon{
                /* border: 1px solid RED; */
                height:fit-content;
                /* justify-content: space-between; */
                display: flex;
                padding-top:8px;
            /* cursor: pointer; */
            
            }

            .icon button{
            
                border:0;
                outline: 0;
                background: #Fafafa;
            }
            .app-icon{
                margin-left:20px;
                margin-right: 20px;
                /* border: 1px solid black; */
                cursor: pointer;
            }
        .menu-icon{
            cursor: pointer;
        }


        .growth-heading{
                border:1px solid black; 
                height: fit-content;
                font-family: Lato;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 12px;
                letter-spacing: 0.01em;
                text-transform: capitalize;
                color: #454545;
                /* padding: 10px; */
                padding-bottom: 5px;
                padding-top: 5px ;
            
            }

    .stuhide{
        display: none;
    }
    /* .dropStudy{
        border:1px solid blue;
    }  */
    .iconStud{
        /* border:1px solid green; */
        flex: 2;
        display: flex;
     justify-content: flex-end;
    }
   
    
    .showdrop{
        display: none;
    }

    @media screen and (max-width: 800px ) {
        .subject-card{
         width: 92%;
         margin: 10px 3%;
        }
    
        .container{
            width: 100%;
        }
    }

    
   

    