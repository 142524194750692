.studymdoc-card{
    display:inline-block;
     width:97%;
     /* height: fit; */
     justify-content: left;
     border-radius: 3px;
     background-color: #FFFFFF;
     margin-left: 2%;  
     /* margin-left: 1%; */
   /* border: 1px solid black; */
     overflow-x: auto;
   
}

.studymdoc-card .hoverStud{
display: none;
transition: all 1s;


}

.studymdoc-card:hover  .hoverStud{
    display: flex;
    background: #FFFFFF;
    width: fit-content;
    padding: 5px 10px;
    transition: all 1s;

    /* border:1px solid blue; */
   
}



.new-files-doc{
    clip-path: polygon(
        0% 0%,
        100% 0%,
        100% 0%,
        88% 100%,
        0% 100%
    );
    /* border:1px solid black; */
    height: fit-content;
    width:50px;
    background: #FE8B37;
    padding-left: 8px;
    margin-right: 15px;
    font-family: Lato;
   font-style: normal;
   font-weight: 500;
   font-size: 8px;
    line-height: 13px;
    position: absolute;

letter-spacing: 0.01em;
text-transform: capitalize;

color: #FFFFFF;
}


.student-doc-card-name{
    /* border: 1px solid blue; */
    display: flex;
    /* width: fit-content; */
    border-bottom: 1px solid #E5E5E5;
    align-items: center;
    justify-content: space-between;
    /* width: 100%; */
    min-width: 700px;
    /* padding: 15px 0px; */

   
   
}

.studydoc{
    /* border: 1px solid blue; */
    height: fit-content;
    width: 40%;
    display: flex;
   padding-top: 17px;
   padding-bottom: 5px;
   
  
}
.sub-headding{
    /* margin-top: 7px;
    margin-left: 9px; */
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 18px;
letter-spacing: 0.01em;
text-transform: capitalize;
color: #454545;


/* border:1px solid blueviolet; */
display: grid;


}

.head-studym{
    /* border:1px solid blueviolet; */
    width: 300px;
       white-space:nowrap;
       overflow:hidden;
       text-overflow:ellipsis;
}
.topic{
     /* border:1px solid black; */
    font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 12px;
/* identical to box height, or 120% */

letter-spacing: 0.01em;
text-transform: capitalize;

color: #A7A7A7;


}


.text-heading {
    /* border:1px solid red; */
    height: fit-content;
   /* margin-left: 50px; */
    width: 160px;
    display:flex;
    align-items:center;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    min-width: 150px;
  /* identical to box height, or 150% */
  
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: #454545;
 
}
.ssssy-button{
    border:0;
    background: none;
}

.image-size{
    display: inline;
    max-height:20px;
    width: 20px;
    align-items: center;
    margin-right: 15px; 
    /* border: 1px solid magenta; */
}
.image{
    height:10px;
  
    margin-right: 15px; 
}
.image-jp{
    height:30px;
    margin-right: 10px;
    margin-left: 18px;
    cursor: pointer;
}

.typeStud{
    width: 100px;
       white-space:nowrap;
       overflow:hidden;
       text-overflow:ellipsis;
}

.view-button1{
    border-radius: 7px;
   font-family: Lato;
   font-size: 13px;
   font-weight: 600;
   color:#FFFFFF;
   border: none; 
   background: #1954E7;
   cursor: pointer; 
   height: fit-content;
   padding:5px 15px;
   margin: 5px 5px 3px 5px;

 }
 
 .download-button1{
   cursor: pointer; 
   border-radius: 7px;
   font-family: Lato;
   font-size: 13px;
   font-weight: 600;
   color:#FFFFFF;
   border: none; 
   background: #0FAA0C;
   cursor: pointer; 
   height: fit-content;
   padding:5px 15px;
   margin: 5px 5px 3px 5px;
 }

 

 @media screen and (max-width: 700px ) {
    .studymdoc-card{
    width: 93%;
       
    }
    .head-studym{
        width: 150px;

    }
    


}