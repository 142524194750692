.doc-card{
  /* border: 1px solid blue; */
  display: flex;
  /* width: fit-content; */
  border-bottom: 1px solid #E5E5E5;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 900px;
 
}

.doc-card-name{
  /* border:1px solid blue; */
  min-width: 400px;
  display: flex;
  padding: 10px;
  /* justify-content: space-between; */
 
  }

.doc-card-image{
  /* border: 1px solid green; */
  height: fit-content;
  width:50px;
  margin-right: 30px;
 
  }

   
.doc-card-heading{
  /* border: 1px solid darkgoldenrod; */
  /* width: fit-content; */
  display: flex;
  flex-direction: column;
  width: 300px;
       white-space:nowrap;
       overflow:hidden;
       text-overflow:ellipsis;
      
}


.doc-card-heading-b{
  font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 12px;
/* identical to box height, or 86% */

letter-spacing: 0.01em;
margin-top: 2px;
color: #454545;


}

.doc-card-heading-s{
  font-family: Lato;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 12px;
/* identical to box height, or 120% */

letter-spacing: 0.01em;

color: #918C8C;
margin-top: 7px;


}


.doc-card-items{
  align-items: center;
/*  */
/* border:1px solid red; */
width: 300px;
white-space:nowrap;
overflow:hidden;
text-overflow:ellipsis;
display: flex;
height: fit-content;
padding: 10px;
text-align: center;
/* margin-top: 10px; */
/* width: fit-content; */
margin-left: 5px;


}
.doc-card-items-image1{
  /* border:1px solid gold; */
  height:30px;
  /* width:50px; */
  margin-right: 10px;
  

}
.doc-card-items-image{
  /* border:1px solid gold; */
  height:fit-content;
  /* width:50px; */
  margin-top: 5px;
  margin-right: 10px;
  

}

.doc-card .button-hover{
  display: none;
  transition: all 0.5s;
  }
  
  .doc-card:hover  .button-hover{
  display: flex;
  background: #FFFFFF;
  width: fit-content;
  padding: 5px 15px;
  
  }
  
  .view-button{
     border-radius: 7px;
    font-family: Lato;
    font-size: 13px;
    font-weight: 600;
    color:#FFFFFF;
    border: none; 
    background: #1954E7;
    cursor: pointer; 
    height: fit-content;
    padding:5px 15px;
    margin: 5px 5px 3px 5px;

  }
  
  .download-button{
    cursor: pointer; 
    border-radius: 7px;
    font-family: Lato;
    font-size: 13px;
    font-weight: 600;
    color:#FFFFFF;
    border: none; 
    background: #0FAA0C;
    cursor: pointer; 
    height: fit-content;
    padding:5px 15px;
    margin: 5px 5px 3px 5px;
  }
  